function empty(obj) {
    if(!obj) {
        return true;
    }
    for(var i in obj) {
        return false; 
    }
    return true;
}

module.exports = empty;