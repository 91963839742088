// Magic regex to find and replace {token} embedded in the template with values from the interpolations object
// function template(template, interpolations) {
//     return template.replace(/\{\s*([^}\s]+)\s*\}/g, (_, token) => interpolations[token] );
// }
function template(template, interpolations) {
    return template.replace(/\{\s*([^}\s]+)\s*\}/g, (_, token) => {
        return token in interpolations ? interpolations[token] : `{${token}}`;
    });
}

module.exports = template;
